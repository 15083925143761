<template>
  <div>
    <div class="main-container">
      <div class="newsdetail-bread">
        <span class="cn">新闻详情 /</span>
        <span class="en">News Info</span>
      </div>
      <Breadcrumb>
        <BreadcrumbItem to="/">
          <Icon type="ios-home-outline"></Icon>
          首页
        </BreadcrumbItem>
        <BreadcrumbItem to="/news">
          <Icon type="md-list" />
          新闻中心
        </BreadcrumbItem>
        <BreadcrumbItem >
          详情
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="notice-content">
        <div class="title">
          {{ detailData.title }}
        </div>
        <div class="abstract">
          发布时间:
          <Tag color="default">{{ new Date(detailData.publishTime).format("yyyy-MM-dd") }}</Tag>
          记者:
          <Tag color="default">{{ detailData.reporter }}</Tag>
          来源:
          <Tag color="default">{{ detailData.origin }}</Tag>
        </div>
        <div class="content  ql-container ql-snow">
          <div v-html="detailData.content" class="ql-editor"/>
        </div>
      </div>
      <div class="goto">
        <div class="prev">
          <Tag :style="{'width':'130px'}">上一篇</Tag>
          <router-link v-if="detailData.pre  != null" :to="'/news-detail/'+detailData.pre.id">
            {{ detailData.pre.title }}
          </router-link>
          <span v-else>没有了...</span>
        </div>
        <div class="next">
          <Tag :style="{'width':'130px'}">下一篇</Tag>

          <router-link v-if="detailData.next != null" :to="'/news-detail/'+detailData.next.id">
            {{ detailData.next.title }}
          </router-link>
          <span v-else>没有了...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.snow.css'
import {queryNewsDetail} from "@/api/front-api";
export default {
  name: 'About',
  props:['id'],
  components: {
  },
  data:function (){
    return{
      detailData: { }
    }
  },
  methods:{
    loadNews(){
      queryNewsDetail({id:this.id}).then(resp=>{
        this.detailData = resp.biz;
      })
    }
  },
  mounted() {
    this.loadNews();
  }
}
</script>

<style scoped>

.contact{
  width: 80%;
  left: 50%;
  min-height: 600px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.newsdetail-bread {
  background: url("../../assets/newsdetail.jpg") no-repeat 100%;
  background-size: cover;
  height: 150px;
  color: #f0f5ff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 10%;
}

.newsdetail-bread span.cn{
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}
.newsdetail-bread span.en{
  font-size: 24px;
  font-family: "Times New Roman";
}


.notice-content {
  margin-top: 16px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #515a6e !important;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
}

.notice-content .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.notice-content .abstract {
  font-size: 15px;
}

.content {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: left;
  text-align: left;
  text-indent: 2em;
  min-height: 500px;
}

.goto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

}

.goto div {
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.next a,
.prev a {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #515a6e !important;
}

.next a:hover,
.prev a:hover {
  color: #87d9d2 !important;
}
.ivu-breadcrumb{
  margin-top: 5px;
}
.ql-container.ql-snow{
  border: none !important;
}
</style>
