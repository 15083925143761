export function loadArea() {
    return [
        {
            id: 1,
            name: '南昌市'
        },
        {
            id: 2,
            name: '九江市'
        },
        {
            id: 3,
            name: '景德镇市'
        },
        {
            id: 4,
            name: '上饶市'
        },
        {
            id: 5,
            name: '鹰潭市'
        },
        {
            id: 6,
            name: '抚州市'
        },
        {
            id: 7,
            name: '新余市'
        },
        {
            id: 8,
            name: '宜春市'
        },
        {
            id: 9,
            name: '修水市'
        },
        {
            id: 10,
            name: '吉安市'
        },
        {
            id: 11,
            name: '宁都市'
        },
        {
            id: 12,
            name: '赣州市'
        },
        {
            id: 13,
            name: '萍乡市'
        }
    ]
}

export function loadNoticeType() {
    return [
        {
            id: 1,
            name: '招标公告',
        },
        {
            id: 2,
            name: '招标预告',
        },
        {
            id: 3,
            name: '中标公告',
        },
        {
            id: 4,
            name: '中标榜单',
        },
        {
            id: 5,
            name: 'VIP招投标培训',
        },{
            id: 6,
            name: 'vip保证金',
        },{
            id: 7,
            name: '投标保函',
        },{
            id: 8,
            name: '履约保函',
        },{
            id: 9,
            name: '质保保函',
        },{
            id: 10,
            name: '能源',
        },{
            id: 11,
            name: '机械设备',
        },{
            id: 12,
            name: 'IT',
        },{
            id: 13,
            name: '通讯及信息技术',
        },{
            id: 14,
            name: '安全防护',
        },
    ]
}
