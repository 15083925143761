import axios from 'axios'
import {Message} from 'view-design';
// import Cookies from 'js-cookie'

// Cookies.set('JSESSIONID', '89318B5D8677567B0E3D8272155CCEB0')
// create an axios instance
const service = axios.create({
    baseURL: "/", // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.body && res.body.code === '999997') {
            window.location.href = res.body.biz.redirectUrl
            return res
        }

        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== '000000' && res.code != '200001') {
            Message.error(res.desc || "error")
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },

    error => {
        if (302 === error.response.status) {
            window.location.href = '/login.html'
        } else {
            Message.error(error.message)
            return Promise.reject(error)
        }
    }
)

export default service
