<template>
  <div>
    <div class="main-container">
      <div class="notice-bread">
        <span class="cn">招标公告 /</span>
        <span class="en">Transaction Information</span>
      </div>
      <Breadcrumb>
        <BreadcrumbItem to="/">
          <Icon type="ios-home-outline"></Icon>
          首页
        </BreadcrumbItem>
        <BreadcrumbItem to="/notice">
          <Icon type="logo-buffer"></Icon>
          招标公告
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="search-filter">
        <Card dis-hover>
          <div slot="title" class="card-title">分类检索</div>
          <div slot="extra">
            <Input search @on-search="searchKeyword" v-model="keyword" placeholder="关键词"/>
          </div>
          <div>
            <Row :style="{'height':'50px'}">
              <Col span="2">
                <h4>招标分类:</h4>
              </Col>
              <Col span="22" :style="{'text-align':'left'}">
                <Button size="small" :style="{'margin':'1px 3px'}" @click="changeType(item.id)"
                        :type="item.id === currentType ? 'primary':'default'" v-for="item in typeList"
                        :key="item.id">{{ item.name }}
                </Button>
              </Col>
            </Row>
            <Divider/>
            <Row>
              <Col span="2">
                <h4>信息行业:</h4>
              </Col>
              <Col span="22" :style="{'text-align':'left'}">
                <Button size="small" :style="{'margin':'1px 3px'}" @click="changeClassify(item.id)"
                        :type="item.id === currentClassify ? 'primary':'default'" v-for="item in classifyData"
                        :key="item.id">{{ item.classifyName }}
                </Button>
              </Col>
            </Row>
            <Divider/>
            <Row>
              <Col span="2">
                <h4>项目区域:</h4>
              </Col>
              <Col span="22" :style="{'text-align':'left'}">
                <Button size="small" :style="{'margin':'3px 3px'}" @click="changeArea(item.id,item.areaName)"
                        :type="item.id === currentArea ? 'primary':'default'" v-for="item in areaData"
                        :key="item.id">{{ item.areaName }}
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
        <div style="padding-top: 10px">
          <Card>
            <Table :columns="ifbColumns" :data="noticeData"></Table>
            <div style="margin-top: 10px">
              <Page :total="total" :page-size="pageSize" show-total @on-change="changePage"/>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {queryClassify, queryNotice,getAreaList} from "@/api/front-api";
import {loadNoticeType} from "@/api/const";


export default {
  name: 'Notice',
  components: {},
  props:['type'],
  data: function () {
    return {
      currentType: Number(this.type) || null,
      currentClassify: this.$route.params.classify || null,
      currentArea: null,
      currentAreaName: '',
      typeList: [],
      classifyData: [],
      areaData: [],
      keyword: '',
      ifbColumns: [
        {
          title: '公告标题',
          key: 'title',
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: "/notice-detail/" + params.row.id
              }
            }, params.row.title);
          }
        },
        {
          title: '招标分类',
          key: 'noticeType',
          width: 150,
          render: (h, params) => {
            let text = '';
            switch (params.row.noticeType) {
              case 1:
                text = '招标公告';
                break;
              case 2:
                text = "招标预告";
            }
            return h('span', {}, text);
          }
        },
        {
          title: '项目区域',
          key: 'area',
          width: 150
        },
        {
          title: '发布时间',
          key: 'publishTime',
          width: 200,
          render: (h, params) => {
            let text = new Date(params.row.publishTime).format("yyyy-MM-dd");
            return h('span', {}, text);
          }
        }
      ],
      noticeData: [],
      total: 0,
      currentPageNo: 1,
      pageSize: 15
    }
  },

  methods: {
    changeClassify(id) {
      this.currentClassify = id;
      this.loadNotice();
    },
    changeArea(id, name) {
      this.currentArea = id;
      this.currentAreaName = name;
      this.loadNotice();
    },
    changeType(id) {
      this.currentType = id;
      this.loadNotice();
    },
    loadClassify() {
      queryClassify().then(resp => {
        this.classifyData = resp.biz;
        this.classifyData.unshift({
          id: null,
          classifyName: '全部'
        })
      })
    },
    loadNotice() {
      console.log("type:"+this.type)
      console.log("currentType:"+this.currentType)
      let param = {
        keyword: this.keyword,
        classify: this.currentClassify,
        noticeType: this.currentType,
        area: this.currentAreaName,
        pageSize: this.pageSize,
        pageNo: this.currentPageNo,
      };
      queryNotice(param).then(resp => {
        this.noticeData = resp.biz.rows;
        this.total = resp.biz.totalCount;
        this.currentPageNo = resp.biz.pageNo;
      })
    },
    changePage(no) {
      this.currentPageNo = no;
      this.loadNotice();
    },
    searchKeyword() {
      this.loadNotice();
    }
  },
  mounted() {
    this.loadClassify();
    getAreaList().then(resp => {
      this.areaData = resp.biz;
      this.areaData.unshift({
        id: null,
        areaName: '全部'
      },)
    })

    this.typeList = loadNoticeType();
    this.typeList.unshift({
      id: null,
      name: '全部',
    })
    this.loadNotice();
  }
}
</script>

<style scoped>
div >>> .ivu-card-extra {
  top: 5px;
}

.search-filter {
  margin-top: 5px;
}

.notice-bread {
  background: url("../../assets/notice-bg.png") no-repeat 100%;
  background-size: cover;
  height: 150px;
  color: #f0f5ff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 10%;
}

.notice-bread span.cn {
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.notice-bread span.en {
  font-size: 24px;
  font-family: "Times New Roman";
}

.ivu-table-cell a {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  color: #515a6e !important;
}

.ivu-table-cell a:hover {
  color: #87d9d2 !important;
}

.ivu-row{
  height: 50px !important;
}

.ivu-breadcrumb{
  margin-top: 5px;
}

.ivu-divider{
  margin: 10px 0;
}

</style>
