import request from '@/utils/request'
//提交留言
export function postMessage(message) {
    return request({
        url: '/ifb/front/submitMessage',
        method: 'post',
        params: message
    })
}


//查询公告列表
export function queryNotice(pageVo) {
    return request({
        url: '/ifb/front/pageNotice',
        method: 'get',
        params: pageVo
    })
}


//查询行业类型列表
export function queryClassify() {
    return request({
        url: '/ifb/front/classify',
        method: 'get'
    })
}

//查询公告详情
export function queryNoticeDetail(p) {
    return request({
        url: '/ifb/front/notice',
        method: 'get',
        params: p
    })
}

//查询新闻列表
export function queryNews(pageVo) {
    return request({
        url: '/ifb/front/pageNews',
        method: 'get',
        params: pageVo
    })
}
//查询新闻详情
export function queryNewsDetail(p) {
    return request({
        url: '/ifb/front/news',
        method: 'get',
        params: p
    })
}


//查询当前登录用户
export function getUser() {
    return request({
        url: '/ifb/front/getUser',
        method: 'get'
    })
}

export function changePassword(p) {
    return request({
        url: '/ifb/front/changePassword',
        method: 'post',
        params: p
    })
}
export function registerUser(data) {
    return request({
        url: '/ifb/front/register',
        method: 'post',
        data: data
    })
}


//退出登录
export function logout() {
    return request({
        url: '/ifb/logout',
        method: 'get'
    })
}

//查询网站配置信息
export function getSiteConfig() {
    return request({
        url: '/ifb/front/getSiteConfig',
        method: 'get'
    })
}
//查询网站配置信息
export function getBanner() {
    return request({
        url: '/ifb/front/getBanner',
        method: 'get'
    })
}

export function getAreaList() {
    return request({
        url: '/ifb/front/getAreaList',
        method: 'get'
    })
}