<template>
  <div class="main-container">
    <div style="width: 100%;margin-left: 50%;transform: translateX(-50%)">
      <ShowSwiper/>
    </div>
    <div class="information-wrapper">
      <div class="main">
        <div class="card-wrapper">
          <Card :style="{width:'100%', 'min-height': '300px'}">
            <div slot="title" class="card-title">
              <Icon type="ios-albums-outline" />&nbsp;
              招标公告
            </div>
            <div slot="extra" :style="{'padding-top':'6px'}">
              <router-link to='/notice/1'> 更多+ </router-link>
            </div>
            <div>
              <Row v-for="item in noticeData" :key="item.id">
                <Col span="20" style="text-align: left">
                  <router-link :to="'/notice-detail/'+item.id">{{ item.title }}</router-link>
                </Col>
                <Col span="4">
                  {{ new Date(item.publishTime).format("yyyy-MM-dd") }}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        <div class="card-wrapper">
          <Card :style="{width:'100%', 'min-height': '200px'}">
            <div slot="title" class="card-title"><Icon type="md-bookmarks" />&nbsp;中标公告</div>
            <div slot="extra" :style="{'padding-top':'6px'}">
              <router-link to='/notice/3'> 阅读更多>></router-link>
            </div>
            <div>
              <Row v-for="item in hitNotice" :key="item.id">
                <Col span="20" style="text-align: left">
                  <router-link :to="'/notice-detail/'+item.id">{{ item.title }}</router-link>
                </Col>
                <Col span="4">
                  {{ new Date(item.publishTime).format("yyyy-MM-dd") }}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
      <div class="sider">
        <MemberCard :siteConfig="siteConfig"/>
      </div>
    </div>
    <div class="information-wrapper footer">
      <div class="left">
        <div class="card-wrapper">
          <Card :style="{width:'100%', 'min-height': '300px'}">
            <div slot="title" class="card-title"><Icon type="md-stats" />&nbsp;中标榜单</div>
            <div slot="extra" :style="{'padding-top':'6px'}">
              <router-link to='/notice/4'> 阅读更多>></router-link>
            </div>
            <div>
              <Row v-for="item in hitRankData" :key="item.id">
                <Col span="20" style="text-align: left">
                  <router-link :to="'/notice-detail/'+item.id">{{ item.title }}</router-link>
                </Col>
                <Col span="4">
                  {{ new Date(item.publishTime).format("yyyy-MM-dd") }}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
      <div class="right">
        <div class="card-wrapper">
          <Card :style="{width:'100%', 'min-height': '300px'}">
            <div slot="title" class="card-title"><Icon type="logo-buffer" />&nbsp;新闻中心</div>
            <div slot="extra" :style="{'padding-top':'6px'}">
              <router-link to='/news'> 阅读更多>></router-link>
            </div>
            <div>
              <Row v-for="item in newsData" :key="item.id">
                <Col span="20" style="text-align: left">
                  <router-link :to="'/news-detail/'+item.id">{{ item.title }}</router-link>
                </Col>
                <Col span="4">
                  {{ new Date(item.publishTime).format("yyyy-MM-dd") }}
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ShowSwiper from "@/components/ShowSwiper";
import MemberCard from "@/components/MemberCard";
import {queryNotice,queryNews,getSiteConfig} from "@/api/front-api";
export default {
  name: "Home",
  components: {
    ShowSwiper,
    MemberCard
  },
  data: function () {
    return {
      noticeData:[],
      newsData:[],
      hitNotice:[],
      hitRankData:[],
      siteConfig:{}
    }
  },
  mounted() {
    this.loadNotice();
    this.loadNews();
    this.loadHitNotice();
    this.loadHitRank();
    getSiteConfig().then(resp=>{
      this.siteConfig = resp.biz;
    })
  },
  methods:{
    loadNotice(){
      queryNotice({pageNo:1,pageSize:5,noticeType:1}).then(resp=>{
        this.noticeData = resp.biz.rows;
      });
    },
    loadNews(){
      queryNews({pageNo:1,pageSize:5}).then(resp=>{
        this.newsData = resp.biz.rows;
      })
    },
    loadHitNotice(){
      queryNotice({pageNo:1,pageSize:5,noticeType:3}).then(resp=>{
        this.hitNotice = resp.biz.rows;
      })
    },
    loadHitRank(){
      queryNotice({pageNo:1,pageSize:5,noticeType:4}).then(resp=>{
        this.hitRankData = resp.biz.rows;
      })
    }

  }
}
</script>

<style>


.information-wrapper {
  width: 100%;
  display: -webkit-flex; /* Safari */
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  padding-top: 10px;
}

.sider {
  display: flex;
  flex-direction: column;
  width: 30%;
  min-width: 300px;
}

@media screen and (max-width: 680px) {
  .sider {
    width: 100%;
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 380px;
  padding-right: 10px;
}
.information-wrapper.footer{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  padding: 0;
}
.information-wrapper .left{
  width: 49%;
}
.information-wrapper .right{
  width: 49%;
}

.card-wrapper {
  width: 100%;
  margin-bottom: 10px;
}

.card-title {
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
}

.main .ivu-card-head,
.left .ivu-card-head,
.right .ivu-card-head
{
  background-color: #569dd5;
  color: #FFFFFF;
  height: 45px;
  font-size: 16px;
}

a.span-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #515a6e;
}

.ivu-row {
  height: 40px;
  line-height: 16px;
  font-size: 14px;
}

.ivu-card-extra a {
  color: #FFFFFF;
}

.ivu-col a {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  color: #515a6e !important;
}

.ivu-col a:hover {
  color: #87d9d2 !important;
}

.ivu-card-extra a:hover{
  color: #FFFFFF;
  font-weight: bold;
}


</style>
