<template>
  <div>
    <div class="fix-header">
      <div class="logo">
        <div><span class="cn">{{ siteConfig.siteCnName }}</span><br/>
          <span class="en">{{ siteConfig.siteEnName }}</span>
        </div>
      </div>
      <div class="phone">
        <div>
          <Icon type="ios-call-outline" size="30" color="#46a2e9"/>
          <span>{{ siteConfig.telPhone }}</span></div>
      </div>
    </div>
    <div class="fix-menu">
      <div>
        <Menu mode="horizontal" theme="primary" :active-name="active" @on-select="menuSelect">
          <menu-item to="/index" name="home" >
            网站首页
          </menu-item>
          <menu-item to="/news" name="news">
            新闻中心
          </menu-item>
          <submenu name="1">
            <template slot="title">
              招标信息
            </template>
            <menu-item name="1">
              招标公告
            </menu-item>
            <menu-item name="2">
              招标预告
            </menu-item>
            <menu-item name="3">
              中标公告
            </menu-item>
            <menu-item name="4">
              中标榜单
            </menu-item>
          </submenu>
          <submenu name="5">
            <template slot="title">
              招标服务
            </template>
            <menu-item name="5">
              VIP服务
            </menu-item>
          </submenu>
          <submenu name="3">
            <template slot="title">
              投资融资
            </template>
            <menu-item name="6">
              VIP保证金
            </menu-item>
            <menu-item name="7">
              投标保函
            </menu-item>
            <menu-item name="8">
              履约保函
            </menu-item>
            <menu-item name="9">
              质保保函
            </menu-item>
          </submenu>
          <submenu name="10">
            <template slot="title">
              招标分类
            </template>
            <menu-item name="10">
              能源
            </menu-item>
            <menu-item name="11">
              机械设备
            </menu-item>
            <menu-item name="12">
              IT
            </menu-item>
            <menu-item name="13">
              通讯及信息技术
            </menu-item>
            <menu-item name="14">
              安全防护
            </menu-item>
          </submenu>
          <menu-item to="/about" name="about">
            关于我们
          </menu-item>
        </Menu>
      </div>
    </div>
  </div>


</template>

<script>


export default {
  name: "FixHeader",
  props: ['active','siteConfig'],
  data: function () {
    return {
      loginStatus: false,
      nav: [
        {
          id: 1,
          title: '网站首页',
          url: '/index'
        }, {
          id: 2,
          title: '新闻中心',
          url: '/news'
        }, {
          id: 3,
          title: '招标公告',
          url: '/notice'
        }, {
          id: 4,
          title: '中标公告',
          url: '/hitNotice'
        }, {
          id: 5,
          title: '联系我们',
          url: '/about'
        }
      ],
      userInfo: null
    }
  },
  mounted() {

  },
  methods: {
    menuSelect(name){
      if (name > 0 && name < 15){
        this.$router.push({path:'/notice/'+name+'?_t='+Math.random()});
      }
    }
  }
}
</script>

<style scoped>
.fix-header {
  width: 100%;
  height: 100px;
  background: url('../assets/bg.jpg') no-repeat;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  top: 0;
}

.logo {
  height: 100px;
  width: 300px;
  padding-top: 10px;;
}

.logo .cn {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 32px;
  font-weight: bolder;
  color: #0c7cc3;
}

.logo .en {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #2e74bf;
}

.phone {
  width: 300px;
  height: 50px;
  line-height: 50px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #2e74bf;
}

.ivu-menu {
  display: inline-block;
}

.fix-menu {
  background-color: #2e82bf !important;
  margin-bottom: 10px;
  height: 38px;
}

.ivu-menu-primary {
  background: none;
}

.ivu-menu-horizontal {
  height: 38px !important;
  line-height: 38px !important;
}

.ivu-menu-item {
  font-size: 14px;
  font-weight: bold;
  border-bottom: none;
  margin: 0 10px;
}

.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item {
  color: #2d8ef5;
  border-bottom: none;
}

.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active {
  font-weight: bold;
  border-bottom: 3px solid #FFFFFF;
  color: #FFFFFF;
}

.ivu-menu-horizontal.ivu-menu-light:after {
  display: none;
}

.information-title a {
  font-size: 18px;
  font-weight: bold;
  color: #085689;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 300px;

}
.fix-menu >>> .ivu-menu-submenu-title{
  font-weight: bold !important;
}
.fix-menu >>> .ivu-select-dropdown{
  background-color: rgba(70,162,233,0.5) !important;
}

.fix-menu >>> .ivu-select-dropdown .ivu-menu-item{
  color: #FFFFFF !important;
  font-weight: normal !important;
}

.fix-menu >>> .ivu-icon-ios-arrow-down:before{
  content: none !important;
}

.fix-menu >>> .ivu-select-dropdown .ivu-menu-item:hover{
  background-color: #085689 !important;
}
</style>
