import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/view/Home';
import News from "@/pages/view/News";
import Notice from "@/pages/view/Notice";
import About from "@/pages/view/About";
import NoticeDetail from "@/pages/view/NoticeDetail";
import NewsDetail from "@/pages/view/NewsDetail";
Vue.use(Router);
export default new Router({
    history:'history',
    routes: [
        {
            path: '/',
            name: 'root',
            component: Home
        },
        {
            path: '/notice',
            name: 'notice',
            component: Notice
        },
        {
            path: '/notice/:type',
            name: 'noticeType',
            component: Notice,
            props:true
        },
        {
            path: '/index',
            name: 'index',
            component: Home
        },
        {
            path: '/news',
            name: 'news',
            component: News
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/notice-detail/:id',
            name: 'noticeDetail',
            component: NoticeDetail,
            props:true
        },
        {
            path: '/news-detail/:id',
            name: 'newsDetail',
            component: NewsDetail,
            props:true
        }
    ]
})
