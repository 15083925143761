<template>
  <div>
    <div class="main-container">
      <div class="news-bread">
        <span class="cn">最新动态 /</span>
        <span class="en">News</span>
      </div>
      <Breadcrumb>
        <BreadcrumbItem to="/">
          <Icon type="ios-home-outline"></Icon>
          首页
        </BreadcrumbItem>
        <BreadcrumbItem to="/news">
          <Icon type="md-list" />
          新闻中心
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="search-filter">
        <div class="filter">
          <Input :style="{width:'250px'}" search @on-search="searchKeyword" v-model="keyword" placeholder="关键词"/>
        </div>
        <div style="padding-top: 10px">
          <Card>
            <Table :columns="columns" :data="newsData"></Table>
            <div style="margin-top: 10px">
              <Page :total="total" :page-size="pageSize" show-total @on-change="changePage"/>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {queryNews} from "@/api/front-api";
export default {
  name: 'News',
  components: {},
  data: function () {
    return {
      keyword:'',
      columns: [
        {
          title: '新闻标题',
          key: 'title',
          render:(h,params)=>{
            return h('router-link',{
              props:{
                to:"/news-detail/"+params.row.id
              }
            },params.row.title);
          }
        },
        {
          title: '发布时间',
          key: 'publishTime',
          width: 200,
          render:(h,params)=>{
            let text = new Date(params.row.publishTime).format("yyyy-MM-dd");
            return h('span',{},text);
          }
        }
      ],
      newsData: [],
      total: 0,
      currentPageNo: 1,
      pageSize: 20
    }
  },
  mounted() {
    this.loadNews();
  },
  methods: {
    searchKeyword() {
      this.loadNews();
    },
    loadNews(){
      let param = {
        pageNo:this.currentPageNo,
        pageSize:this.pageSize,
        keyword:this.keyword
      }
      queryNews(param).then(resp=>{
        this.newsData = resp.biz.rows;
        this.total = resp.biz.totalCount;
        this.currentPageNo = resp.biz.pageNo;
      });
    },
    changePage(no){
      this.currentPageNo = no;
      this.loadNews()
    }
  }
}
</script>

<style>
div.filter {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 50px;
  line-height: 50px;
}

.news-bread {
  background: url("../../assets/news-bg.png") no-repeat 100%;
  background-size: cover;
  height: 150px;
  color: #f0f5ff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 10%;
}

.news-bread span.cn{
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}
.news-bread span.en{
  font-size: 24px;
  font-family: "Times New Roman";
}
.ivu-table-cell a {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  color: #515a6e !important;
}

.ivu-table-cell a:hover {
  color: #87d9d2 !important;
}

.ivu-breadcrumb{
  margin-top: 5px;
}

</style>
