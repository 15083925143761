<template>
  <div>
    <div class="main-container">
      <div class="zbdetail-bread">
        <span class="cn">招标详情 /</span>
        <span class="en">Transaction Information</span>
      </div>
      <Breadcrumb>
        <BreadcrumbItem to="/">
          <Icon type="ios-home-outline"></Icon>
          首页
        </BreadcrumbItem>
        <BreadcrumbItem to="/notice">
          <Icon type="logo-buffer"></Icon>
          招标公告
        </BreadcrumbItem>
        <BreadcrumbItem>
          详情
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="notice-content">
        <div class="title">
          {{ detailData.title }}
        </div>
        <div class="abstract">
          发布时间:
          <Tag color="default">{{ new Date(detailData.publishTime).format("yyyy-MM-dd") }}</Tag>
          行业信息类型:
          <Tag color="default">{{ detailData.classifyName }}</Tag>
          所属区域:
          <Tag color="default">{{ detailData.area }}</Tag>
        </div>
        <div class="content ql-container ql-snow">
          <div class="ql-editor" v-if="detailData.content != null" v-html="detailData.content"></div>
          <div class="no-auth" v-else>
            <p style="font-weight: bold">可能因为以下原因，造成您不能查看此信息
            <p>
            <p>1、由于您长时间未浏览信息已退出登录,请
              <router-link to="/index">重新登录</router-link>
            </p>
            <p>2、您的账户可能没有相关的访问权限,请联系供应商</p>
            <p>3、联系电话:{{ siteConfig.telPhone }} </p>
          </div>
        </div>
        <div class="attachment" v-if="detailData.attachments != null">
          <strong>原文链接：</strong> <a :href="detailData.attachments" target="_blank">{{ detailData.attachments }}</a>
        </div>
      </div>
      <div class="goto">
        <div class="prev">
          <Tag :style="{'width':'130px'}">上一篇</Tag>
          <a v-if="detailData.pre != null" href="javascript:void(0)"
             @click="goto(detailData.pre.id)">{{ detailData.pre.title }}</a>
          <span v-else>没有了...</span>
        </div>
        <div class="next">
          <Tag :style="{'width':'130px'}">下一篇</Tag>
          <a v-if="detailData.next != null" href="javascript:void(0)"
             @click="goto(detailData.next.id)">{{ detailData.next.title }}</a>
          <span v-else>没有了...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {queryNoticeDetail, getSiteConfig} from "@/api/front-api";
import 'quill/dist/quill.snow.css'


export default {
  name: 'About',
  components: {},
  props: ['id'],
  data: function () {
    return {
      detailData: {},
      siteConfig: {}
    }
  },
  mounted() {

    this.loadNotice();
    getSiteConfig().then(resp => {
      this.siteConfig = resp.biz
    })
  },
  methods: {
    loadNotice() {
      queryNoticeDetail({id: this.id}).then(resp => {
        this.detailData = resp.biz;
      })
    },
    goto(id) {
      this.$router.push({path: '/notice-detail/' + id});
    }

  }
}
</script>

<style scoped>


.zbdetail-bread {
  background: url("../../assets/zbinfo.png") no-repeat 100%;
  background-size: cover;
  height: 150px;
  color: #f0f5ff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 10%;
}

.zbdetail-bread span.cn {
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.zbdetail-bread span.en {
  font-size: 24px;
  font-family: "Times New Roman";
}

.notice-content {
  margin-top: 16px;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #515a6e !important;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
}

.notice-content .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.notice-content .abstract {
  font-size: 15px;
}

.content {
  margin-top: 20px;
  display: flex;
  flex-flow: row;
  justify-content: left;
  text-align: left;
  text-indent: 2em;
  min-height: 500px;
}

.goto {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

}

.goto div {
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.next a,
.prev a {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  color: #515a6e !important;
}

.next a:hover,
.prev a:hover {
  color: #87d9d2 !important;
}

.notice-content {
  padding: 0 15%;
}

.no-auth {
  width: 100%;
  margin-top: 10%;
  margin-left: 30%;
}

.attachment {
  text-align: left;
  padding: 20px 30px;
}

.ivu-breadcrumb {
  margin-top: 5px;
}
.ql-container.ql-snow{
  border: none !important;
}

.ql-editor >>> td{
  tab-size:0 !important;
  text-indent:0 !important;
}
</style>
