<template>
  <div class="swiper-div">
    <Carousel :autoplay-speed="displaySpeed" :autoplay="true" v-model="currentValue" @on-change="change">
      <CarouselItem v-for="(item,index) in carouselList" :key="index" v-show="itemShow">
        <div class="fix-img" :style="{'background':'url('+item.filePath+') no-repeat'}">
          <div class="mask">
            <transition name="slide-fade">
              <div class="show-text" style="line-height: 20px">
                <div class="title-text">
                  <a class="link" :href="item.linkUrl"> {{ item.titleText }}</a>
                </div>
                <div class="content-text">{{ item.descText }}</div>
              </div>
            </transition>
          </div>
        </div>
      </CarouselItem>
    </Carousel>
  </div>

</template>

<script>

import {getBanner} from '@/api/front-api';

export default {
  name: "ShowSwiper",
  computed: {},
  data() {
    return {
      arrowClass: 'bigArrow',
      itemShow: [],
      carouselList: [],
      currentValue: 0,
      displaySpeed: 3000
    };
  },
  mounted() {
    this.loadBanner();
  },
  methods: {
    change: function (o, v) {
      let self = this;
      self.$set(self.itemShow, o, false);
      self.$set(self.itemShow, v, true);
    },
    loadBanner() {
      getBanner().then(resp => {
        this.carouselList = resp.biz;
      })
    }
  }
}
</script>

<style scoped>

.fix-img {
  top: 150px;
  width: 100%;
  height: 300px;
  line-height: 300px;
  overflow: hidden;
  background-size: cover !important;
}

.mask {
  background: rgba(49, 52, 57, 0.48);
  opacity: 0.8;
  overflow: hidden;
  height: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10px;

}


.ivu-carousel-item {
  height: 100%;
  overflow: visible;
}

.ivu-carousel {
  top: 0px;
}

.show-text {
  font-size: 20px;
  color: white !important;
  top: 200px;
}

.title-text {
  font-size: 50px !important;
  line-height: 50px;
  margin-bottom: 20px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}

.content-text {
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 24px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里是超出几行省略 */
  overflow: hidden;
}

.ivu-carousel-list {
  text-align: center;
  vertical-align: middle;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 1.5s ease;
}

.slide-fade-leave-active {
  transition: all 1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for below version 2.1.8 */
{
  transform: translateY(-180px);
  opacity: 0;
}

a.link {
  color: #ffffff;
}

button:hover,
.button:hover {
  box-shadow: inset 0 0 0 1px #2ebaae;
  color: #2ebaae !important;
}

.swiper-div >>> .ivu-carousel-arrow {
  border-radius: 0 !important;
  background: none;
}

.swiper-div >>> .ivu-icon {
  width: 100% !important;
  height: 100% !important;
  font-size: 50px;
  font-weight: normal;
}
</style>
