<template>
  <div id="app">
    <FixHeader active="1" :siteConfig="siteConfig"/>
      <router-view :key="key" />
    <FixFooter :siteConfig="siteConfig" />
  </div>
</template>

<script>
import {getSiteConfig} from '@/api/front-api'
import FixHeader from "@/components/FixHeader";
import FixFooter from "@/components/FixFooter";

export default {
  name: 'App',
  components: {
    FixFooter,
    FixHeader
  },
  data:function (){
    return{
      siteConfig:{}
    }
  },
  computed:{
    key(){
      return this.$route.path+Math.random();
    }
  },
  mounted() {
    getSiteConfig().then(res => {
      this.siteConfig = res.biz;
      document.title = this.siteConfig.siteCnName;
    })
  }
}
</script>

<style>
body{
  background-color: #f6fcfe;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-container {
  width: 80%;
  margin-left: 50%;
  min-height: 615px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  margin-top: 10px;
}
.ivu-breadcrumb{
  text-align: left;
}

.card-title {
  text-align: left;
  padding-left: 10px;
}


a {
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 100%;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
}
</style>
