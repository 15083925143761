<template>
  <div style="width: 100%">
    <Card dis-hover>
      <div slot="title" v-if="member == null" class="card-title">
        <div class="title-box">商户登录</div>
      </div>
      <div v-else slot="title" class="card-title">
        <span>商户信息</span>
      </div>
      <div v-if="member == null">
        <Form ref="loginForm" :model="loginForm" :rules="rules" :label-width="80">
          <FormItem label="登录名" prop="loginName" :style="{'width':'80%'}">
            <Input v-model="loginForm.loginName" placeholder="登录名"></Input>
          </FormItem>
          <FormItem label="密码" prop="password" :style="{'width':'80%'}">
            <Input type="password" v-model="loginForm.password" placeholder="密码"></Input>
          </FormItem>
          <div v-if="error !== ''" style="color: red;text-align: left;margin: 3px 0;padding: 0 10%">{{ error }}</div>
          <div style="padding-left: 15%">
            <div style="width:80%;display: flex;justify-content: space-around">
              <Button type="primary" long @click="login()">登录</Button>
            </div>
            <div style="width: 100%;font-size: 13px;margin-top: 6px">无账号？点击这里<a
                style="color: #569dd5;text-decoration: none;font-size: 16px" href="javascript:void(0);"
                @click="register"> 注册 </a></div>
          </div>
        </Form>
      </div>
      <div v-else>
        <span style="font-weight: bold">  您好：{{ member.nickName }}</span>

        <div class="operation">
          <Button type="primary" ghost @click="logout()">退出</Button>
          <Button type="error" ghost @click="resetClick()">修改密码</Button>
        </div>
        <div v-show="member.member" style="margin-top: 4px">
          <span style="font-weight: bold">您的会员到期时间：
            <span v-if="member.expireTime !== null">
              {{ new Date(member.expireTime).format("yyyy-MM-dd hh:mm:ss") }}
            </span>
             <span style="color:green;font-weight: bold" v-else>
              永久
          </span>
          </span>
        </div>
        <div v-show="member.member === false" style="margin-top: 4px;text-align: left;">
          <p>您的账户可能没有相关的访问权限,请联系供应商</p>
          <p>联系电话:{{siteConfig.telPhone}} </p>
        </div>
      </div>
    </Card>
    <Modal v-model="resetModel"
           title="修改密码"
           @on-ok="resetPass"
           :loading="loading"
           @on-cancel="cancel">
      <Form :model="resetPassForm" ref="resetPassForm" :rules="resetRules" :label-width="80" style="width: 100%">
        <FormItem label="新密码" prop="password" :style="{'width':'80%'}">
          <Input type="password" v-model="resetPassForm.password" placeholder="新密码"></Input>
        </FormItem>
        <FormItem label="确认密码" prop="confirmPass" :style="{'width':'80%'}">
          <Input type="password" v-model="resetPassForm.confirmPass" placeholder="确认密码"></Input>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="registModel">
      <p slot="header" style="text-align:center">
        <span>用户注册</span>
      </p>
      <div>
        <Form :model="registerForm" ref="registerForm" :rules="registRoles" :label-width="100" style="width: 100%">
          <FormItem label="登录名" prop="userName">
            <Input v-model="registerForm.userName" placeholder="用户登录名"></Input>
          </FormItem>
          <FormItem label="用户密码" prop="password">
            <Input type="password" v-model="registerForm.password" placeholder="登录密码"></Input>
          </FormItem>
          <FormItem label="确认密码" prop="confirmPass">
            <Input type="password" v-model="registerForm.confirmPass" placeholder="确认密码"></Input>
          </FormItem>
          <FormItem label="姓名" prop="nickName">
            <Input v-model="registerForm.nickName" placeholder="用户昵称"></Input>
          </FormItem>
          <FormItem label="产品类型" prop="productInfo">
            <Input v-model="registerForm.productInfo" placeholder="请输入产品类型"></Input>
          </FormItem>
          <FormItem label="公司名称" prop="organizationName">
            <Input v-model="registerForm.organizationName" placeholder="请输入公司名称"></Input>
          </FormItem>
          <FormItem label="联系方式" prop="phone">
            <Input v-model="registerForm.phone" placeholder="请输入联系手机号"></Input>
          </FormItem>
          <FormItem label="联系人" prop="contact">
            <Input v-model="registerForm.contact" placeholder="请输入联系人"></Input>
          </FormItem>
        </Form>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="primary" size="large" long @click="submitRegister">注册</Button>
        <div style="margin-top: 5px">已注册网站账号?请移步至登录入口</div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {getUser, logout, changePassword, registerUser} from '@/api/front-api';
import axios from 'axios'

export default {
  name: "MemberCard",
  props:['siteConfig'],
  data: function () {
    // eslint-disable-next-line no-unused-vars
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入登录密码'));
      } else {
        if (this.registerForm.confirmPass !== '') {
          // 对第二个密码框单独验证
          this.$refs.registerForm.validateField('confirmPass');
        }
        callback();
      }
    };
    // eslint-disable-next-line no-unused-vars
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致，请重新输入!'));
      } else {
        callback();
      }
    };
    return {
      resetModel: false,
      registModel: false,
      loading: true,
      member: null,
      error: '',
      resetPassForm: {
        password: '',
        confirmPass: ''
      },
      registerForm: {
        userName: '',
        password: '',
        confirmPass: '',
        nickName: '',
        productInfo: '',
        organizationName: '',
        phone: '',
        contact: ''
      },
      registRoles: {
        userName: [
          {required: true, message: '请输入登录名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ],
        confirmPass: [
          {required: true, message: '请输入确认密码', trigger: 'blur'},
          {validator: validatePassCheck, trigger: 'blur'}
        ],
        nickName: [
          {required: true, message: '请输入昵称', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入联系方式', trigger: 'blur'}
        ]
      },
      resetRules: {
        password: [
          {required: true, message: '请输入新密码', trigger: 'blur'}
        ],
        confirmPass: [
          {required: true, message: '请输入确认密码', trigger: 'blur'}
        ],
      },
      rules: {
        loginName: [
          {required: true, message: '请输入登录名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
      },
      loginForm: {
        loginName: '',
        password: ''
      }
    }
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    login() {
      this.error = '';
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          axios.post('/ifb/login?username=' + this.loginForm.loginName + "&password=" + this.loginForm.password, null)
              .then((res) => {
                if (res.data.code === "000000") {
                  this.$Message.success('登录成功');
                  this.error = '';
                  this.loadUser();
                } else {
                  this.error = res.data.desc;
                }
              });
        }
      });
    },
    register() {
      this.registModel = true;
    },
    submitRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          registerUser(this.registerForm).then(() => {
            this.$Message.success("注册成功，自动登录");
            this.loginForm.loginName = this.registerForm.userName;
            this.loginForm.password = this.registerForm.password;
            this.login();
            this.$refs.registerForm.resetFields();
            this.registModel = false;
          })
        }
      });
    },
    loadUser() {
      getUser().then(resp => {
        this.member = resp.biz;
      });
    },
    logout() {
      logout().then((resp) => {
        console.log(resp)
        this.member = null;
      })
    },
    resetClick() {
      this.resetModel = true;
    },
    resetPass() {
      this.$refs.resetPassForm.validate((valid) => {
        if (valid) {
          if (this.resetPassForm.password === this.resetPassForm.confirmPass) {
            changePassword({password: this.resetPassForm.password}).then(() => {
              this.$Message.success('修改成功');
              this.resetModel = false;
              this.logout();
            })
          } else {
            this.$Message.error('确认密码错误，请确认密码');
            this.loading = false;
            return false;
          }
        }
      });
    },
    cancel() {
      this.resetPassForm = {}
    }
  }
}
</script>

<style scoped>
.operation {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20%;
}

.title-box {
  width: 40%;
  border-bottom: #569dd5 solid 2px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #569dd5;
}
</style>
