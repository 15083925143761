<template>
  <div class="fix-footer">

    <div class="company-info">
      <div>{{siteConfig.footerContactInfo}}</div>
    </div>
    <div class="copy-right">
      <div>
        <a style="margin-left: 10px" href="https://beian.miit.gov.cn/">{{siteConfig.beianInfo}}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixFooter",
  props:['siteConfig'],
  data:function (){
  }
}
</script>

<style scoped>
.fix-footer a{
  color: #FFFFFF;
  font-size: 16px;
}

.fix-footer {
  width: 100%;
  background-color: #569dd5;
  height: 100px;
  padding-top: 10px;
  color: #FFFFFF;
  font-size: 16px;
}

.company-info,
.friend-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  line-height: 40px;
}

.company-info div {
  margin: 0 5px;
}
</style>
