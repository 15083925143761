<template>
  <div>
    <div class="main-container">
      <div class="about-bread">
        <span class="cn">关于我们 /</span>
        <span class="en">About Us</span>
      </div>
      <Breadcrumb>
        <BreadcrumbItem to="/">
          <Icon type="ios-home-outline"></Icon>
          首页
        </BreadcrumbItem>
        <BreadcrumbItem to="/about">
          <Icon type="md-contacts" />
          联系我们
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="contact">
        <Card>
          <div slot="title">
           <span class="message-title">期待您的留言</span>
          </div>
          <Form ref="messageForm" :model="messageForm" :rules="validateRule">
            <FormItem prop="company">
              <Input v-model="messageForm.company" placeholder="企业名称"/>
            </FormItem>
            <FormItem prop="nickName">
              <Input v-model="messageForm.nickName" placeholder="您的姓名"/>
            </FormItem>
            <FormItem prop="contact">
              <Input v-model="messageForm.contact" placeholder="您的联系方式"/>
            </FormItem>
            <FormItem prop="message">
              <Input v-model="messageForm.message" type="textarea" :rows="4" placeholder="留言信息"/>
            </FormItem>
            <FormItem>
              <Button type="primary" @click="submitForm">提交留言</Button>
            </FormItem>
          </Form>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import {postMessage} from "@/api/front-api"
export default {
  name: 'About',
  components: {
  },
  data:function (){
    return{
      messageForm:{
        company:'',
        nickName:'',
        contact:'',
        message:''
      },
      validateRule:{
        company:[
          { required: true, message: '企业名称不能为空', trigger: 'blur' }
        ],
        nickName:[
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        contact:[
          { required: true, message: '联系方式不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods:{
    submitForm(){
      this.$refs['messageForm'].validate((valid)=>{
        if (valid){
          postMessage(this.messageForm).then(()=>{
            this.$Message.success("留言成功，我们收到留言后会及时联系您");
            this.$refs.messageForm.resetFields();
          })
        }
      })
    }
  }
}
</script>

<style scoped>

.contact{
  width: 40%;
  margin-left: 25%;
  margin-top: 30px;
  margin-bottom: 100px;
}

.about-bread {
  background: url("../../assets/about-bg.png") no-repeat 100%;
  background-size: cover;
  height: 150px;
  color: #f0f5ff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding-left: 10%;
}

.about-bread span.cn{
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}
.about-bread span.en{
  font-size: 24px;
  font-family: "Times New Roman";
}
.message-title{
  color: #515a6e;
  transition: color .2s ease-in-out;
  font-size: 20px;
}
.ivu-breadcrumb{
  margin-top: 5px;
}
</style>
